<template>
  <div>
    <el-row>
      <el-col :span="8">
        <div id="goods" style="height: 300px"></div>
      </el-col>
      <el-col :span="8">
        <div id="expense" style="height: 300px"></div>
      </el-col>
      <el-col :span="8">
        <div id="labor" style="height: 300px"></div>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="8">
        <div id="goodsBudget" style="height: 300px"></div>
      </el-col>
      <el-col :span="8">
        <div id="expenseBudget" style="height: 300px"></div>
      </el-col>
      <el-col :span="8">
        <div id="laborBudget" style="height: 300px"></div>
      </el-col>
    </el-row>

    <el-timeline>
      <el-timeline-item
        v-for="item in billInfo.change"
        :key="item.id"
        :timestamp="item.changeDate | datetime('YYYY年MM月DD日 HH:mm:ss')"
        placement="top"
      >
        <el-card shadow="hover">
          <h4>{{ item.remark }}</h4>
          <p>
            <el-tag type="warning">{{ item.changeItem }}</el-tag
            >，由 <el-tag type="success">{{ item.oldValue || "空" }}</el-tag
            >更改为
            <el-tag type="info">{{ item.newValue || "空" }}</el-tag>
          </p>
        </el-card>
      </el-timeline-item>
    </el-timeline>
    <el-button-group>
      <el-button type="primary" @click="$router.push({ name: 'ProjectList' })"
        >列表</el-button
      >
      <el-button type="warning" @click="close">关闭</el-button>
      <el-button
        type="danger"
        @click="deleteProject"
        v-if="billInfo.billState == 1"
        >删除</el-button
      >
    </el-button-group>
  </div>
</template>

<script>
export default {
  data() {
    return {
      routeTab: "项目",
      billInfo: {},
    };
  },
  mounted() {
    this.getData(this.$route.params.id);
  },
  methods: {
    getData(id) {
      this.$get("Project/DetailInfo", {
        id,
      }).then((r) => {
        this.billInfo = r;
        if (this.billInfo.projectName) {
          this.routeTab = this.billInfo.projectName;
        }
        this.$nextTick(() => {
          this.setCharts();
        });
      });
    },
    setCharts() {
      this.$echarts.init(document.getElementById("goods")).setOption({
        title: {
          left: "center",
          text: "物资",
        },
        tooltip: {
          trigger: "item",
          formatter: "{b} : ￥{c}元 ({d}%)",
        },
        series: [
          {
            type: "pie",
            radius: "50%",
            data: this.billInfo.goods,
          },
        ],
      });
      this.$echarts.init(document.getElementById("expense")).setOption({
        title: {
          left: "center",
          text: "费用",
        },
        tooltip: {
          trigger: "item",
          formatter: "{b} : ￥{c}元 ({d}%)",
        },
        series: [
          {
            type: "pie",
            radius: "50%",
            data: this.billInfo.expense,
          },
        ],
      });
      this.$echarts.init(document.getElementById("labor")).setOption({
        title: {
          left: "center",
          text: "人工",
        },
        tooltip: {
          trigger: "item",
          formatter: "{b} : ￥{c}元 ({d}%)",
        },
        series: [
          {
            type: "pie",
            radius: "50%",
            data: this.billInfo.labor,
          },
        ],
      });
      let budget = {
        type: "gauge",
        min: 0,
        max: 125,
        splitNumber: 5,
        axisLine: {
          // 坐标轴线
          lineStyle: {
            width: 3,
          },
        },
        axisTick: {
          lineStyle: {
            color: "auto",
          },
        },
        splitLine: {
          length: 5, // 属性length控制线长
        },
        pointer: {
          width: 3,
        },
      };

      this.$echarts.init(document.getElementById("goodsBudget")).setOption({
        tooltip: {
          formatter: (params) => {
            let data = params.data;
            return (
              "预算：￥" + data.budget + "元<br/>实际：￥" + data.amount + "元"
            );
          },
        },
        series: [
          {
            ...budget,
            data: [this.billInfo.goodsBudget],
          },
        ],
      });
      this.$echarts.init(document.getElementById("expenseBudget")).setOption({
        tooltip: {
          formatter: (params) => {
            let data = params.data;
            return (
              "预算：￥" + data.budget + "元<br/>实际：￥" + data.amount + "元"
            );
          },
        },
        series: [
          {
            ...budget,
            data: [this.billInfo.expenseBudget],
          },
        ],
      });
      this.$echarts.init(document.getElementById("laborBudget")).setOption({
        tooltip: {
          formatter: (params) => {
            let data = params.data;
            return (
              "预算：￥" + data.budget + "元<br/>实际：￥" + data.amount + "元"
            );
          },
        },
        series: [
          {
            ...budget,
            data: [this.billInfo.laborBudget],
          },
        ],
      });
    },
    deleteProject() {
      this.$confirm(
        "将删除【" + this.billInfo.projectName + "】, 是否继续?",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      )
        .then(() => {
          this.deleteById();
        })
        .catch(() => {});
    },
    deleteById() {
      this.$post("Project/DeleteProject?id=" + this.billInfo.id).then((r) => {
        this.$message.success("操作成功！");
        this.$g.deleteAttach(this.billInfo.attachId);
        this.$store.commit("removeBaseInfo", {
          info: "Project",
          id: this.billInfo.id,
        });
        this.$tabs.close();
      });
    },
    close() {
      this.$tabs.close();
    },
  },
};
</script>

<style>
</style>